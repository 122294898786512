import React from 'react';
import { t } from '@lingui/macro';

import daDk from '@rover/react-lib/src/images/homepage/store-button/ios/da-dk.svg?url';
import deAt from '@rover/react-lib/src/images/homepage/store-button/ios/de-at.svg?url';
import deCh from '@rover/react-lib/src/images/homepage/store-button/ios/de-ch.svg?url';
import deDe from '@rover/react-lib/src/images/homepage/store-button/ios/de-de.svg?url';
import enCa from '@rover/react-lib/src/images/homepage/store-button/ios/en-ca.svg?url';
import enGb from '@rover/react-lib/src/images/homepage/store-button/ios/en-gb.svg?url';
import enIe from '@rover/react-lib/src/images/homepage/store-button/ios/en-ie.svg?url';
import enUs from '@rover/react-lib/src/images/homepage/store-button/ios/en-us.svg?url';
import esEs from '@rover/react-lib/src/images/homepage/store-button/ios/es-es.svg?url';
import fiFi from '@rover/react-lib/src/images/homepage/store-button/ios/fi-fi.svg?url';
import frCa from '@rover/react-lib/src/images/homepage/store-button/ios/fr-ca.svg?url';
import frFr from '@rover/react-lib/src/images/homepage/store-button/ios/fr-fr.svg?url';
import itIt from '@rover/react-lib/src/images/homepage/store-button/ios/it-it.svg?url';
import nbNo from '@rover/react-lib/src/images/homepage/store-button/ios/nb-no.svg?url';
import nlBe from '@rover/react-lib/src/images/homepage/store-button/ios/nl-be.svg?url';
import nlNl from '@rover/react-lib/src/images/homepage/store-button/ios/nl-nl.svg?url';
import plPl from '@rover/react-lib/src/images/homepage/store-button/ios/pl-pl.svg?url';
import ptPt from '@rover/react-lib/src/images/homepage/store-button/ios/pt-pt.svg?url';
import svSe from '@rover/react-lib/src/images/homepage/store-button/ios/sv-se.svg?url';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import {
  getCountryUrlPrefix,
  US_COUNTRY_URL_PREFIX,
} from '@rover/shared/js/constants/roverGeo.constants';
import { getPath } from '@rover/shared/js/utils/getFullUrl';

import type { Props as AppStoreButtonProps } from './AppStoreButton';
import AppStoreButton from './AppStoreButton';

export const APP_STORE_LINK = (country: string): string =>
  `https://itunes.apple.com/${country}/app/rover.com/id547320928?mt=8&uo=4`;

const images = {
  'en-gb': enGb,
  'en-ie': enIe,
  'en-us': enUs,
  'en-ca': enCa,
  'es-es': esEs,
  'da-dk': daDk,
  'de-at': deAt,
  'de-ch': deCh,
  'de-de': deDe,
  'fi-fi': fiFi,
  'it-it': itIt,
  'fr-fr': frFr,
  'fr-ca': frCa,
  'sv-se': svSe,
  'nl-be': nlBe,
  'nl-nl': nlNl,
  'nb-no': nbNo,
  'pl-pl': plPl,
  'pt-pt': ptPt,
};

export type Props = Omit<
  AppStoreButtonProps,
  'ariaLabel' | 'os' | 'linkPath' | 'imageSrc' | 'altText' | 'buttonProps'
> & {
  linkPath?: string;
};

const IosAppStoreButton = ({ linkPath, ...others }: Props): JSX.Element => {
  const { i18n, locale } = useI18n();
  const image = images[locale] || enUs;
  const requestUrlPath = getPath();
  const country = getCountryUrlPrefix(requestUrlPath) ?? US_COUNTRY_URL_PREFIX;
  return (
    <AppStoreButton
      {...others}
      os="ios"
      imageSrc={image}
      altText={i18n._(t`Download on the App Store Badge`)}
      linkPath={linkPath || APP_STORE_LINK(country)}
      ariaLabel={i18n._(t`Download on the App Store`)}
    />
  );
};

export default IosAppStoreButton;
