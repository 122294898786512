import React, { useCallback } from 'react';

import { Image, Link } from '@rover/kibble/core';
import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';
import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';

export type Props = {
  altText: string;
  ariaLabel: string;
  eventName?: string;
  imageHeight?: string;
  imageSrc: string;
  linkPath: string;
  os: string;
  target?: string;
};

const AppStoreButton: React.FC<Props> = ({
  altText,
  ariaLabel,
  eventName,
  imageHeight = '50',
  imageSrc,
  linkPath,
  os,
  target = '_blank',
}) => {
  const handleInteraction = useCallback((): void => {
    const effectiveEventName = eventName || 'navigation-mobile-app';
    try {
      fireDataLayerEvent({
        event: effectiveEventName,
        os,
      });
    } catch (error) {
      captureError(error as Error);
    }
  }, [os, eventName]);

  return (
    <Link
      aria-label={ariaLabel}
      href={linkPath}
      onClick={handleInteraction}
      rel="noopener noreferrer"
      target={target}
    >
      <Image src={imageSrc} width="auto" height={imageHeight} description={altText} />
    </Link>
  );
};

export default AppStoreButton;
