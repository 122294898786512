import React from 'react';
import { t } from '@lingui/macro';

import daDk from '@rover/react-lib/src/images/homepage/store-button/android/da-dk.svg?url';
import deAt from '@rover/react-lib/src/images/homepage/store-button/android/de-at.svg?url';
import deCh from '@rover/react-lib/src/images/homepage/store-button/android/de-ch.svg?url';
import deDe from '@rover/react-lib/src/images/homepage/store-button/android/de-de.svg?url';
import enCa from '@rover/react-lib/src/images/homepage/store-button/android/en-ca.svg?url';
import enGb from '@rover/react-lib/src/images/homepage/store-button/android/en-gb.svg?url';
import enIe from '@rover/react-lib/src/images/homepage/store-button/android/en-ie.svg?url';
import enUs from '@rover/react-lib/src/images/homepage/store-button/android/en-us.svg?url';
import esEs from '@rover/react-lib/src/images/homepage/store-button/android/es-es.svg?url';
import fiFi from '@rover/react-lib/src/images/homepage/store-button/android/fi-fi.svg?url';
import frCa from '@rover/react-lib/src/images/homepage/store-button/android/fr-ca.svg?url';
import frFr from '@rover/react-lib/src/images/homepage/store-button/android/fr-fr.svg?url';
import itIt from '@rover/react-lib/src/images/homepage/store-button/android/it-it.svg?url';
import nbNo from '@rover/react-lib/src/images/homepage/store-button/android/nb-no.svg?url';
import nlBe from '@rover/react-lib/src/images/homepage/store-button/android/nl-be.svg?url';
import nlNl from '@rover/react-lib/src/images/homepage/store-button/android/nl-nl.svg?url';
import plPl from '@rover/react-lib/src/images/homepage/store-button/android/pl-pl.svg?url';
import ptPt from '@rover/react-lib/src/images/homepage/store-button/android/pt-pt.svg?url';
import svSe from '@rover/react-lib/src/images/homepage/store-button/android/sv-se.svg?url';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import {
  getCountryUrlPrefix,
  US_COUNTRY_URL_PREFIX,
} from '@rover/shared/js/constants/roverGeo.constants';
import { getPath } from '@rover/shared/js/utils/getFullUrl';

import type { Props as AppStoreButtonProps } from './AppStoreButton';
import AppStoreButton from './AppStoreButton';

export const GOOGLE_PLAY_STORE_LINK = (country: string, language: string): string =>
  `http://play.google.com/store/apps/details?id=com.rover.android&gl=${country}&hl=${language}`;

const images = {
  'en-gb': enGb,
  'en-ie': enIe,
  'en-us': enUs,
  'en-ca': enCa,
  'es-es': esEs,
  'da-dk': daDk,
  'de-at': deAt,
  'de-ch': deCh,
  'de-de': deDe,
  'fi-fi': fiFi,
  'it-it': itIt,
  'fr-fr': frFr,
  'fr-ca': frCa,
  'sv-se': svSe,
  'nl-be': nlBe,
  'nl-nl': nlNl,
  'nb-no': nbNo,
  'pl-pl': plPl,
  'pt-pt': ptPt,
};

export type Props = Omit<
  AppStoreButtonProps,
  'ariaLabel' | 'os' | 'imageSrc' | 'altText' | 'linkPath' | 'buttonProps'
> & {
  linkPath?: string;
};

const AndroidAppStoreButton = ({ linkPath, ...others }: Props): JSX.Element => {
  const { i18n, locale } = useI18n();
  const image = images[locale] || enUs;
  const language = locale.slice(0, 2);
  const requestUrlPath = getPath();
  const country = getCountryUrlPrefix(requestUrlPath) ?? US_COUNTRY_URL_PREFIX;
  return (
    <AppStoreButton
      {...others}
      os="android"
      imageSrc={image}
      altText={i18n._(t`Get it on Google Play Badge`)}
      linkPath={linkPath || GOOGLE_PLAY_STORE_LINK(country, language)}
      ariaLabel={i18n._(t`Get it on Google Play`)}
    />
  );
};

export default AndroidAppStoreButton;
